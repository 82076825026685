<template>
  <div>
    <abb-receipt :orderid="orderHeaderId" />
    <div class="preview-container">
      <!-- <button @click="printReceipt">Print Invoice</button> -->
    </div>
  </div>
</template>

<script>
import AbbReceipt from "./AbbReceipt.vue";

export default {
  data() {
    return {
      orderHeaderId: "",
      receiptHeight: 300, // Default height in mm
    };
  },
  created() {
    this.orderHeaderId = this.$route.params.orderid;
    console.log("Loading ABB Receipt...");
  },
  mounted() {
    this.calculateReceiptHeight();
  },
  methods: {
    calculateReceiptHeight() {
      this.$nextTick(() => {
        const receiptElement = this.$el.querySelector(".invoice-container");
        if (receiptElement) {
          const heightInPixels = receiptElement.scrollHeight; // Get total height in pixels
          const heightInMM = (heightInPixels / 96) * 25.4; // Convert px to mm
          this.receiptHeight = Math.ceil(heightInMM); // Round up to avoid clipping
          this.updatePrintStyle();
        }
      });
    },
    updatePrintStyle() {
      const style = document.createElement("style");
      style.type = "text/css";
      style.id = "dynamic-print-style";
      style.innerHTML = `
        @media print {
          @page {
            size: 80mm ${this.receiptHeight}mm;
            margin: 0;
          }
          .invoice-container {
            height: ${this.receiptHeight}mm !important;
            overflow: visible !important;
          }
        }
      `;
      const existingStyle = document.getElementById("dynamic-print-style");
      if (existingStyle) {
        existingStyle.remove(); // Remove the old style to avoid duplication
      }
      document.head.appendChild(style);
    },
    printReceipt() {
      window.print(); // Trigger print preview
    },
  },
  components: {
    AbbReceipt,
  },
};
</script>

<style lang="scss">
.invoice-container {
  background-color: white;
  margin: 0 auto !important;
  font-family: "SukhumvitSet-Medium";
  font-size: 0.9rem;
  width: 80mm !important;
  padding-bottom: 20mm; /* เพิ่ม padding ด้านล่าง */
}

.preview-container {
  text-align: center;
  margin-top: 20px;
}

@media print {
  @page {
    size: 80mm auto; /* Dynamic height will be set via JavaScript */
    margin: 0;
  }
  html, body {
    margin: 0;
    padding: 0;
  }
  .invoice-container {
    background-color: white;
    margin: 0 !important;
    box-shadow: none;
    overflow: visible !important;
    padding-bottom: 20mm !important; /* เพิ่ม padding ด้านล่างในโหมด print */
  }
  .preview-container {
    display: none; /* Hide buttons during print */
  }
}
</style>
